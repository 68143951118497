.dropdownLoading{
    position: fixed;
    top: 0%;
    left: 0%;
    background: linear-gradient(rgba(2, 21, 107, 0.445),rgba(1, 78, 78, 0.445));
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    /* color: rgb(53, 26, 3); */
}
.dropdownLoading .dropDivLoading{
    margin-top: 45%;
    margin-left: 45%;
    border-radius: 5px;
    /* background-color: rgba(7, 136, 136, 0.568); */

}
.dropdownLoading .addProduct h2{
    font-family: MingLiU_HKSCS-ExtB;
}
.dropdownLoading .dropDiv .addProduct div:last-child{
    display: flex;
    justify-content: space-between;
}
.dropdownLoading .addProduct{
    padding:10px
}