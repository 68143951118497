#main-page-background{
    /* background-color: black; */
    position: fixed;
    background: url("images/mainBackground.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    z-index: -99;
    height: 100%;
  }
  #main-page-background .opacity{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(179, 179, 179,0.75); 
  }
  
  .mainClass{
  }

  h2{
    font-family: 'Times New Roman', Times, serif;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: bold;
  }